import React from 'react';
import PropTypes from 'prop-types';
import { graphql, Link } from 'gatsby';
import Helmet from 'react-helmet';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

import { PAGES, BASE_URL } from 'config';
import { FORMAT_GRAPH_DATA, getImageUrl } from 'utilities';
import { Layout } from 'containers';
import { ActivityList, VideoIntro } from 'layouts';
import { Banner, Breadcrumbs } from 'components';

const breadcrumbs = [
  {
    name: PAGES.ACTIVITIES.TITLE,
    absolute_slug: PAGES.ACTIVITIES.PATH,
  },
];

const ActivitiesPage = ({ data }) => {
  const categories = FORMAT_GRAPH_DATA(data.allActivityCategories);
  const activities = FORMAT_GRAPH_DATA(data.allActivityPages);
  const images = data.allActivityImages.edges;

  return (
    <Layout page="activities">
      <Helmet
        title="New Zealand Highlights | Adventure Travel NZ | Haka Tours"
        meta={[
          {
            name: 'description', content: 'Adventure travel in New Zealand is highly rated. Tours feature New Zealand highlights & activities for all interests including movies, culture, water & nature.',
          },
          { name: 'og:title', content: 'New Zealand Highlights | Adventure Travel NZ | Haka Tours' },
          {
            name: 'og:description', content: 'Adventure travel in New Zealand is highly rated. Tours feature New Zealand highlights & activities for all interests including movies, culture, water & nature.',
          },
        ]}
        link={[{ rel: 'canonical', href: `${BASE_URL}/activities/` }]}
      />

      <Banner backgroundImage="https://static-hakatours.imgix.net/activity-page-banner.jpg?fit=crop&auto=format">
        <h1 className="c-heading c-heading--h0">NEW ZEALAND HIGHLIGHTS</h1>
      </Banner>

      <section className="l-activity-category-list">
        <div className="l-container">
          <Breadcrumbs list={breadcrumbs} />

          <div className="l-activity-category-list__header">
            <h2 className="c-heading c-heading--h2">ADD ACTIVITIES TO YOUR TOUR</h2>
            <p>
              Customise your adventure travel in New Zealand by adding a variety of activities to your tour.
              Whatever your style of adventure – whether it’s pushing your own boundaries leaping out of a plane at 16,000 feet or taking an iconic Milford Sound Cruise.
              Checkout the activities we consider New Zealand highlights – add the activities that appeal to you to make your New Zealand tour unique to you.
              You can also view our <Link to="/activity-brochure"> activity brochure </Link> for a summary of activities available an to help you choose.
            </p>
          </div>

          <div className="l-grid l-grid--3">
            {categories.map(category => (
              <Link
                key={category.id}
                to={category.absolute_slug}
                className="c-activity-category-card"
              >
                <div className="c-activity-category-card__image">
                  <LazyLoadImage
                    src={getImageUrl({
                      id: category.picture_id,
                      images,
                      width: 380,
                      height: 205,
                    })}
                    wrapperClassName="lazy-img-wrapper"
                    effect="blur"
                    alt={category.name}
                  />
                </div>

                <div className="c-activity-category-card__content">
                  <h3 className="c-heading c-heading--h3">
                    {category.name}
                  </h3>
                  <p>
                    {category.short_description}
                  </p>
                </div>
              </Link>
            ))}
          </div>
        </div>
      </section>

      <ActivityList
        list={activities}
        showFilters
      />

      <VideoIntro
        title="How our tours work"
        videoUrl="https://www.youtube.com/watch?v=ITSEGjWanWY&feature=youtu.be"
        hideReviews
        disableVideoPromo={false}
      >
        <h4 className="c-heading c-heading--h4">Step 1: SELECT YOUR BASE TOUR</h4>
        <p>
          Every tour includes: accommodation, transport, tour guide, breakfast and just a few key inclusions which we guarantee are not be missed!
        </p>
        <h4 className="c-heading c-heading--h4">Step 2: CUSTOMISE YOUR TOUR</h4>
        <p>
          Customise your trip by adding a whole range of activities, experiences, accommodation upgrades, extensions and activity packs.
        </p>
        <h4 className="c-heading c-heading--h4">Step 3: Book and manage your tour online</h4>
        <p>
          Secure your seat for a $99 deposit, then pay it off over time – the balance is due 60 days prior to departure.
          During this time you can add more activities or customise your tour.
        </p>
        <p>
          It’s that easy! If you have any questions at all, then please <a href="/contact-us/" target="_blank">chat with us</a>.
        </p>
      </VideoIntro>
    </Layout>
  );
};

ActivitiesPage.propTypes = {
  data: PropTypes.object.isRequired,
};

export const query = graphql`
  query {
    allActivityCategories {
      edges {
        node {
          id
          api_id
          name
          short_description
          absolute_slug
          picture_id
        }
      }
    }

    allActivityPages(
      filter: {
        is_accommodation_supplier: { eq: false },
        is_activity_supplier: { eq: true },
        guide_app_only: { ne: true },
      }
    )  {
      edges {
        node {
          id
          api_id
          name
          location
          destination_name
          absolute_slug
          addon_category_ids
          sliding_first_picture_id
          options {
            price
            hidden
            archived
            seasonal_pricings {
              id
              price
              available_from
              available_to
              hidden
            }
          }
        }
      }
    }

    allActivityImages {
      edges {
        node {
          api_id
          imgix_url
        }
      }
    }
  }
`;

export default ActivitiesPage;
